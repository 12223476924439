import './bootstrap';
import Swal from 'sweetalert2';
import Alpine from 'alpinejs'
import {Modal} from 'bootstrap';
import Chart from 'chart.js/auto';
import Dropzone from 'dropzone';

window.Dropzone = Dropzone;
window.Chart = Chart;

import 'daterangepicker';
import 'daterangepicker/daterangepicker.css'; // Make sure the CSS is also imported

// import 'datatables.net-bs5';
// import 'datatables.net-buttons-bs5'; // Import the JS file for buttons.bootstrap5

let modalsElement = document.getElementById('livewire-bootstrap-modal');

modalsElement.addEventListener('hidden.bs.modal', () => {
    Livewire.emit('resetModal');
});

Livewire.on('showBootstrapModal', () => {
    let modal = Modal.getInstance(modalsElement);

    if (!modal) {
        modal = new Modal(modalsElement);
    }

    modal.show();
});

Livewire.on('hideModal', () => {
    let modal = Modal.getInstance(modalsElement);

    modal.hide();
});


window.Swal = Swal;
window.Alpine = Alpine

window.deleteConfirm = function(formId)
{
    Swal.fire({
        icon: 'warning',
        text: 'Are you sure?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#e3342f',
    }).then((result) => {
        if (result.isConfirmed) {
            document.getElementById(formId).submit();
        }
    });
}

window.cancelConfirm = function(formId)
{
    Swal.fire({
        icon: 'warning',
        text: 'Are you sure?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#e3342f',
    }).then((result) => {
        if (result.isConfirmed) {
            document.getElementById(formId).submit();
        }
    });
}


Alpine.start()

$(function () {

    $('#send-referral').click( function() {
        $('#newDirectReferralModal').modal('show');
    });

    $('#newDirectReferralModal').on('shown.bs.modal', function(event) {
        var user = $(event.relatedTarget);
        var userId = user.data("user-id");
        var userName = user.data("user-name");
        $('#to_user_name').val(userName);
        $('#to_user_id').val(userId);
        $('#lead_name').focus();
    });

    $('#sendMessageModal').on('shown.bs.modal', function(event) {
        var link = $(event.relatedTarget);
        var userId = link.data("user-id");
        //console.log(userId);
        $('#recipients').val(userId);
        $('#message').focus();
    });

});


// DROPZONE
document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector("#MyDropzone") === null) {
        return; // Exit if the element does not exist
    }
    // Ensure Dropzone is not auto-discovered to prevent any issues since you're creating a manual instance
    Dropzone.autoDiscover = false;

    // Create a Dropzone for the specific element
    var myDropzone = new Dropzone("#MyDropzone", {
        paramName: "file", // The name that will be used to transfer the file
        maxFilesize: 50, // MB, consider adjusting if this is not a typo (50 MB is quite large)
        dictDefaultMessage: "Drop files here",
        dictResponseError: 'Server not Configured',
        uploadMultiple: true,
        createImageThumbnails: true,
        acceptedFiles: ".png,.jpg,.gif,.bmp,.jpeg,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.zip,.txt,.xml,.json",
        addRemoveLinks: true, // Optional: Adds links for removing files
        timeout: 180000, // Optional: Adjust the timeout for the requests (important for large files)
        init: function () {
            // Set up any event handlers
            this.on('completemultiple', function () {
                location.reload();
            });
        },
        // Event handlers
        error: function(file, response) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
            });
            this.removeAllFiles(true); // Passing `true` to removeAllFiles forcibly removes all files including those uploading
        },

        sending: function(file, xhr, formData) {
            //formData.append("_token", document.querySelector('meta[name="csrf-token"]').content); // Add Laravel CSRF token
        },

        success: function(file, response) {
            // Swal.fire(
            //     'Success!',
            //     'File(s) have been uploaded',
            //     'success'
            // );
            this.removeFile(file); // Consider removing only successfully uploaded files
        },

        // Optional: Called when all files in the queue finish uploading.
        queuecomplete: function() {
            // For example, reload a DataTable here if needed
            // $('#documents-table').DataTable().ajax.reload();
        }
    });
});
